<template>
  <v-container>
    <v-row justify="center" class="mt-3">
      <v-col class="text-center">
        <h4 class="text-h4 font-weight-regular">Parse Request</h4>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" lg="12" xl="8">
        <v-card>
          <div class="grey--text text-center py-15" v-if="loading">
            <v-progress-circular indeterminate size="50" />
            <div class="caption my-2">Loading parse request...</div>
          </div>
          <v-card-text v-else-if="currentMatchRequest">
            <v-simple-table :loading="loading" class="text-right">
              <template v-slot:default>
                <tbody>
                  <tr v-if="currentMatchRequest.requestUserId && canShowUserInfo()">
                    <td class="text-right">User ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'user.view',
                          params: { id: currentMatchRequest.requestUserId },
                        }"
                      >
                        {{ currentMatchRequest.requestUserName }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-if="currentMatchRequest.ocrCardId && canShowNidOcrCard()">
                    <td class="text-right" width="200px">OCR Card ID</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'nid-ocr-card.single',
                          params: { requestId: currentMatchRequest.ocrCardId },
                        }"
                      >
                        {{ currentMatchRequest.ocrCardId || "-" }}
                      </v-btn>
                    </td>
                  </tr>

                  <tr v-for="item in items" :key="item.name">
                    <td>{{ item.name }}</td>
                    <td class="text-left text--monospaced">
                      {{ emptyChecker(item.value(currentMatchRequest)) }}
                    </td>
                  </tr>

                  <tr v-if="currentMatchRequest.requestImageId && canShowOcrRequest()">
                    <td class="text-right">Request Image Id</td>
                    <td class="text-left">
                      <v-btn
                        outlined
                        :to="{
                          name: 'ocr-request.single',
                          params: { md5sum: currentMatchRequest.requestImageId },
                        }"
                      >
                        {{ currentMatchRequest.requestImageId || "-" }}
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="requestImage">
                    <td class="text-right" width="200px">Request Image</td>
                    <td class="text-left">
                      <img
                        v-if="requestImage"
                        alt="content-image"
                        :src="`data:${requestImage.mimeType};base64,${requestImage.imageBase64}`"
                        style="
                          max-height: 250px;
                          width: 100%;
                          object-fit: contain;
                          object-position: center left;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Text</td>
                    <td class="text-left text--monospaced pa-3">
                      <pre
                        style="
                          border: 1px solid #ccc;
                          background: #eee;
                          padding: 10px;
                          border-radius: 5px;
                        "
                        >{{ currentMatchRequest.text }}</pre
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { OCR_REQUEST_SINGLE, PARSE_REQUEST_SINGLE } from "@/constants/api";

export default {
  name: "MatchRequest",
  metaInfo: { title: "Match Request" },
  components: {},
  data() {
    return {
      loading: false,
      requestImage: null,
      currentMatchRequest: null,
      items: [
        {
          name: "Requested At",
          value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
        },
        { name: "Parse Id", value: (item) => item.id },
        { name: "Type", value: (item) => item.type },
      ],
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.loading = true;
      this.fetchMatchRequest(this.$route.params.id)
        .then(this.fetchRequestImage)
        .finally(() => {
          this.loading = false;
        });
    }
  },
  methods: {
    fetchMatchRequest(id) {
      return this.$axios
        .get(`${PARSE_REQUEST_SINGLE}?requestId=${id}`)
        .then((response) => {
          if (response.data.code === 0) {
            this.currentMatchRequest = response.data.data;
          } else {
            throw new Error(response.data.message);
          }
        })
        .catch((err) => {
          this.$iziToast.showError(err);
          this.$router.back();
        });
    },
    fetchRequestImage() {
      if (this.currentMatchRequest.requestImageId) {
        this.$axios
          .get(`${OCR_REQUEST_SINGLE}?md5sum=${this.currentMatchRequest.requestImageId}`)
          .then((response) => {
            if (response.data.code === 0) {
              this.requestImage = response.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    emptyChecker(val, placeholder = "-") {
      return val ? val : placeholder;
    },
  },
};
</script>

<style scoped></style>
